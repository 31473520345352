import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {login, logout} from '../slices/authSlice';
import consts from 'src/constants/consts';

// Define a base query with the ability to refresh tokens
const baseQuery = fetchBaseQuery({
    baseUrl: consts.BASE_URL || 'https://api.raindropcentral.com',
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error?.status === 401) {
        const refreshToken = api.getState().auth.refreshToken;
        if (refreshToken) {
            const refreshResult = await api.dispatch(playersApi.endpoints.refreshToken.initiate(refreshToken));
            if (refreshResult.data) {
                const { token, refreshToken: newRefreshToken } = refreshResult.data;
                api.dispatch(login({ token, refreshToken: newRefreshToken }));
                result = await baseQuery(args, api, extraOptions);
            } else {
                api.dispatch(logout());
            }
        } else {
            api.dispatch(logout());
        }
    }
    return result;
};

export const playersApi = createApi({
    reducerPath: 'playersApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Player', 'PlayersList'],
    endpoints: (builder) => ({
        fetchPlayers: builder.query({
            query: () => '/api/player',
            providesTags: ['PlayersList']
        }),
        createPlayer: builder.mutation({
            query: (playerData) => ({
                url: '/api/player',
                method: 'POST',
                body: playerData,
            }),
            invalidatesTags: ['PlayersList']
        }),
        getPlayerInventoryByUUID: builder.query({
            query: (uuid) => ({ url: `/api/player/inventories/${uuid}` }),
            providesTags: (result, error, uuid) => [{ type: 'Player', id: uuid }],
        }),
        getPlayerByUUID: builder.query({
            query: (uuid) => ({ url: `/api/player/${uuid}` }),
            providesTags: (result, error, uuid) => [{ type: 'Player', id: uuid }],
        }),
        updatePlayer: builder.mutation({
            query: ({ uuid, ...updateData }) => ({
                url: `/api/player/${uuid}`,
                method: 'PUT',
                body: updateData,
            }),
            invalidatesTags: (result, error, uuid) => [{ type: 'Player', id: uuid }, { type: 'PlayersList' }],
        }),
        deletePlayer: builder.mutation({
            query: (uuid) => ({
                url: `/api/player/${uuid}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['PlayersList'],
        }),
    }),
});

export const {
    useFetchPlayersQuery,
    useCreatePlayerMutation,
    useGetPlayerByUUIDQuery,
    useUpdatePlayerMutation,
    useDeletePlayerMutation,
} = playersApi;
