import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';

import Loadable from '../layouts/Loadable';
import ProtectedRoute from '../components/route/ProtectedRoute';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));

// authentication components, lazy-loaded for improved performance
const Login = Loadable(lazy(() => import('../views/authentication/pages/Login')));
const Register = Loadable(lazy(() => import('../views/authentication/pages/Register')));
const ForgotPassword = Loadable(lazy(() => import('../views/authentication/pages/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('../views/authentication/pages/ResetPassword')));
const TwoSteps = Loadable(lazy(() => import('../views/authentication/pages/TwoSteps')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Maintenance = Loadable(lazy(() => import('../views/authentication/Maintenance')));

/* ****OAuth2 Routes Callback***** */
const OAuthCallback = Loadable(lazy(() => import('../views/authentication/pages/OAuthCallback')));

// landing page, lazy-loaded
const Landingpage = Loadable(lazy(() => import('../views/pages/landingpage/Landingpage')));
const AboutPage = Loadable(lazy(() => import('../views/pages/home/About')));
const ContactPage = Loadable(lazy(() => import('../views/pages/home/Contact')));


/* ****Dashboard***** */
const Dashboard = Loadable(lazy(() => import('../views/dashboard/DashboardHome')));
const UserProfile = Loadable(lazy(() => import('../views/dashboard/user-profile/UserProfile')));
const UserInventoryOverview = Loadable(lazy(() => import('../components/dashboard/inventories/player/PlayerDetails')));
const UsersTableList = Loadable(lazy(() => import('../components/dashboard/admin/users/UsersTableList')));
const CreateUser = Loadable(lazy(() => import('../components/dashboard/admin/users/CreateUser')));
const ServersTableList = Loadable(lazy(() => import('../components/dashboard/admin/servers/ServersTableList')));
const ServerDetail = Loadable(lazy(() => import('../components/dashboard/admin/servers/ServerDetail')));
const PlayersTableList = Loadable(lazy(() => import('../components/dashboard/admin/players/PlayersTableList')));
const PlayerDetail = Loadable(lazy(() => import('../components/dashboard/admin/players/PlayerDetail')));
const Leaderboard = Loadable(lazy(() => import('../components/dashboard/ranking/GlobalRankings')));
const UserDetail = Loadable(lazy(() => import('../components/dashboard/admin/users/UserDetail')));
const RollBasedAccess = Loadable(lazy(() => import('../components/dashboard/admin/roles/RollBasedAccessControl')));



// Updated Router configuration without the FullLayout and BlankLayout
const Router = [
  { path: '/', element: <Landingpage /> },
  { path: '/about', element: <AboutPage /> },
  { path: '/contact', element: <ContactPage /> },
  { path: '/login', element: <Login /> },
  { path: '/register', element: <Register /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/reset-password', element: <ResetPassword /> },
  { path: '/two-steps', element: <TwoSteps /> },
  { path: '/maintenance', element: <Maintenance /> },
  { path: '/404', element: <Error /> },
  { path: '*', element: <Navigate to="/404" /> },

  // OAuth callback route
  { path: '/oauth/success', element: <OAuthCallback /> },

  // Dashboard Routes with ProtectedRoute
  {
    path: '/dashboard',
    element: <FullLayout />,
    children: [
      { path: '', element: <ProtectedRoute><Dashboard /></ProtectedRoute> }, // '/dashboard'
      { path: 'user-profile', element: <ProtectedRoute><UserProfile /></ProtectedRoute> },
      { path: 'user-inventories/:uuid', element: <ProtectedRoute><UserInventoryOverview/></ProtectedRoute>},
      { path: 'leaders', element: <ProtectedRoute><Leaderboard /></ProtectedRoute> },
      { path: 'admin/users-list', element: <ProtectedRoute isAdminRequired={true}><UsersTableList /></ProtectedRoute> },
      { path: 'admin/user-detail/:userId', element: <ProtectedRoute isAdminRequired={true}><UserDetail /></ProtectedRoute> },
      { path: 'admin/add-user', element: <ProtectedRoute isAdminRequired={true}><CreateUser /></ProtectedRoute> },
      { path: 'admin/roles', element: <ProtectedRoute isAdminRequired={true}><RollBasedAccess /></ProtectedRoute> },
      { path: 'admin/servers', element: <ProtectedRoute isAdminRequired={true}><ServersTableList /></ProtectedRoute> },
      { path: 'admin/server-detail/:uuid', element: <ProtectedRoute isAdminRequired={true}><ServerDetail /></ProtectedRoute> },
      { path: 'admin/players', element: <ProtectedRoute isAdminRequired={true}><PlayersTableList /></ProtectedRoute> },
      { path: 'admin/player-detail/:uuid', element: <ProtectedRoute isAdminRequired={true}><PlayerDetail /></ProtectedRoute> },
    ],
  },
];

export default Router;
